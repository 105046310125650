<div class="container">
	<div class="row mt-3">
		<div class="col col-lg-12">
			<h1>BOWI International Agency</h1>
		</div>
	</div>

	<div class="row mt-3">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" 
      [ngClass]="{'tab-vertical': verticalTab}" animationDuration="0">
      <mat-tab label="Contact">
          <div *ngIf="!contactSent">
            <form [formGroup]="contactForm">
              <dl class="row">
                <dd class="col-md-2"><label for="inputCompany" class="col-sm-2 col-form-label">Company</label></dd>
                <dt class="col-md-8"><input type="text" class="form-control" id="inputCompany" formControlName="company"></dt>
                <div class="col-md-2"></div>

                <dd class="col-md-2"><label for="inputName" class="col-sm-2 col-form-label">Name</label></dd>
                <dt class="col-md-8"><input type="text" class="form-control" id="inputName" formControlName="name"></dt>
                <div class="col-md-2"></div>

                <dd class="col-md-2"><label for="inputEmail" class="col-sm-2 col-form-label">Email<span class="text-danger">&nbsp;*</span></label></dd>
                <dt class="col-md-8">
                  <input type="text" class="form-control" id="inputEmail" formControlName="email"
                  [ngClass]="{ 'is-invalid': isEmailEmpty() ||  isEmailFormatInvalid()}">
                  <label class="error small form-text invalid-feedback" *ngIf="isEmailEmpty()">This field is required.</label>
                  <label class="error small form-text invalid-feedback" *ngIf="isEmailFormatInvalid()">This field must follow a standard email format.</label>
                </dt>
                <div class="col-md-2"></div>

                <dd class="col-md-2"><label for="inputMessage" class="col-sm-2 col-form-label">Message</label></dd>
                <dt class="col-md-8"><textarea class="form-control" id="inputMessage" style="height: 100px" formControlName="message"></textarea></dt>
                <div class="col-md-2"></div>
              </dl>
              <div class="row mt-3">
                <div class="col-md-6 offset-md-2">
                  <button type="button" class="btn btn-primary btn-lg" (click)="onSubmit()" [attr.disabled]="isFormInvalid()? true: null">Send Message</button>
                </div>
                <div class="col-md-4"></div>
              </div>
            </form>
          </div>
          <div *ngIf="contactSent">
            <div class="row">
              <p>
                Thank you for your message. <br/>
                We will reply to you as soon as possible.
            </div>
          </div>
      </mat-tab>
      <mat-tab label="About">
        <p>Quansic is a swiss based company that acts as the BOWI International Agency that developed and maintains the BOWI Standard.</p>
        <p>The BOWI International Agency centrally allocates the BOWI codes, operates and maintains the BOWI Registry and appoints the BOWI Registration Agencies.</p>
        <br/>
        <p>If you want to become a Registration Agency or have any questions please contact us.</p>
      </mat-tab>
    </mat-tab-group>
	</div>
</div>
