import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bowi-registration',
  templateUrl: './bowi-registration.component.html',
  styleUrls: ['./bowi-registration.component.css']
})
export class BowiRegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
