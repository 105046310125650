<div class="container">
	<div class="row mt-3">
		<div class="col col-md-12">
			<h1>FAQ</h1>
		</div>
	</div>

	<div class="row mt-3">
    <mat-tab-group disableRipple="true" [ngClass]="{'tab-vertical': verticalTab}" animationDuration="0">
      <mat-tab label="FAQ" class="nav-link">
        <div *ngFor="let item of items; index as i">
          <app-page-faq-item [question]="item.question" [answer]="item.answer" [id]="i"></app-page-faq-item>
        </div>
      </mat-tab>
      <mat-tab label="Specifications" class="nav-link">
        <h2>B0-Gr973572-0</h2>
          <h3>a) Syntax</h3>
          <p>A BOWI is composed of 11 alphanumeric characters. Allowed characters are</p>
            <ul>
              <li>Integers from 0 to 9.</li>
              <li>Uppercase characters from A to Z except O, I, L.</li>
            </ul>

          <p>For human readability, a hyphen character may be inserted in positions 3 and 10. Those hyphens should be omitted and ignored in machine readable formats</p>

          <h3>a) Structure</h3>
          <p>The structure of the code is defined as follows:</p>
          <p>
            <strong class="text-primary">Position 1</strong> The letter B. A Bowi code always starts with the letter B. B for Bowi, of course.
          </p>
          <p>
            <strong class="text-primary">Position 2</strong> The block identifier.
          </p>
          <ul>
            <li>0 is the test block. Codes from the block 0 should only be used for test purposes and NEVER be used in production. They may be deprecated at any time.</li>
            <li>1 is the initial valid block.</li>
            <li>All other block characters are reserved.</li>
          </ul>
          <p>
            <strong class="text-primary">Position 3 to 9</strong> Any characters as defined above. Some unfortunate character combinations are filtered out.
          </p>
          <p>
            <strong class="text-primary">Position 10</strong> A check digit in the range 0 to 9. The check digit is calculated from the previous characters to avoid erroneous typing. If the letter l is mistakenly entered instead of the digit 1, it will be detected by the check digit. Details on the check digit algorithm can be found here.
          </p>
          <p>
            <strong class="text-primary">Position 11</strong> Version number. For a given Musical Work, the BOWI metadata can be updated at any time. Each update receives a new version number. The most recent version has the highest ASCII value.
          </p>
      </mat-tab>
      <mat-tab label="Allocation Rules" class="nav-link">
        <h2>Allocation Rules</h2>
        <h3>a) Reference metadata</h3>
        <p>The reference metadata is the minimum metadata set required to allocate a BOWI.</p>
        <ul>

          <li>a Work title.</li>
          <li>at least one Creator name.</li>
          <li>an ISNI or IPI for each Creator.</li>
        </ul>

        <p>The ISNI or IPI identifier is mandatory for each Creator.</p>
        <p>It is not possible to allocate 2 different BOWIs to 2 musical works with the exact same title and list of Creators.</p>

        <h3>b) Extended metadata</h3>
        <p>Additional information may be provided to describe the musical work. All those elements are optional.</p>
        <p><strong class="text-primary">Alternative titles</strong> One or more work titles, along with a language code and a character set.</p>
        <p><strong class="text-primary">Year of Creation</strong> The year in which the work was created.</p>
        <p><strong class="text-primary">Other identifiers</strong> Any other identifiers already allocated to that musical work. It can be an ISWC or a proprietary ID within a namespace.</p>
        <p><strong class="text-primary">Sound Recording </strong> The description of one or more Sound Recording that embed the Musical Work. Sound recording metadata elements are:</p>
        <ul>
          <li>An ISRC code</li>
          <li>A sound recording title</li>
          <li>Name of the featured artist</li>
          <li>ISNI of the featured artist</li>
          <li>Year of release</li>
        </ul>
      </mat-tab>
    </mat-tab-group>
	</div>
</div>
