

<h1>Get a BOWI</h1>
<p>If you would like to get a BOWI, you can register through our registration agency : <br>
Before you start, make sure a BOWI hasn't already been assigned to your work - to do so you can go to our <a href='http://bowi.io/app-registry' target="_blank">registry</a> and search for your work<br>
Note that the success of your registration will depend on the quality of your information
</p>
	<div class="flex">
		<a href="https://registration.quansic.com/" target="_blank">
			<img src='assets/images/quansic.png' class="img-5">
		</a>
	</div>
