import { Component, OnInit } from '@angular/core';

declare var bootstrap: any;

@Component({
  selector: 'app-page-faq',
  templateUrl: './page-faq.component.html',
  styleUrls: ['./page-faq.component.css']
})
export class PageFaqComponent implements OnInit {
  activeTab:string = "";
  verticalTab:boolean = true;
	items:any[];
  	constructor() {
  	  this.activeTab = "faq";
  		this.items = [
  			{
          question: "What does BOWI stands for?",
          answer: "BOWI means the Best Open Work Identifier."
        },
  			{
          question: "Who uses BOWI?",
          answer: "Every parties involved in the music works exploitation (i.e. songwriters, publishers, labels, distributors, platforms, etc.) are likely to use BOWI."
        },
  			{
          question: "What is the scope of BOWI?",
          answer: "Whether they are registered in a Collective Management Organisation or not, under copyright or in the public domain, already exploited or not, all musical works can have a BOWI, without exception." },
  			{
          question: "What is the difference between ISWC and BOWI ?",
          answer: "<p>ISWC is administered by CISAC, the International Confederation of Societies of  Authors and Composers. Only the CMOs members of CISAC are allowed to be an ISWC Registration Agency. The ISWC Registry is only open to the CMOs members of CISAC.</p>"
                + "<p>BOWI is administered by the BOWI International Agency (run by Quansic). All organizations or companies with a legitimate interest in BOWI can apply to become a BOWI Registration Agency. The BOWI Registry is open to everyone.</p>"
        },
  			{
          question: "What is required to obtain a BOWI?",
          answer: "The minimum info required to obtain a BOWI are:<ul>"
                + "<li>The title of the musical work</li>"
                + "<li>The duration</li>"
                + "<li>The year of creation</li>"
                + "<li>At least one creator (full name with an ISNI or an IPI)</li></ul>"
                + "<p>ISWC and MLC Identifiers are welcome to facilitate the identification of the musical work and the creator(s).</p<"
        },
  			{
          question: "I need and ISNI - what do I do ?",
          answer: "<p>ISNI is the international identifier for contributors to creative works.</p>"
                + "<p>You can get an ISNI <a href=\"https://quansic.com/isni-registration/\" target=\"_blank\">here.</a></p>"
        },
  			{
          question: "How much does it cost ?",
          answer: "<p>Please contact us if you want to become a BOWI Partner or Registration Agency</p>"
        },
  			// { question: "Can I delete my BOWI ?", answer: "Not sure...." },
  			{
          question: "How do the Registration Agencies work?",
          answer: "<p>The Registration Agencies are appointed by the BOWI International Agency to provide BOWI: The Registration Agencies are your interlocutor when you want to get a BOWI.</p>"
                + "<p>The Registration Agencies submit requests for new BOWI assignments to the BOWI International Agency which operates the BOWI Registry.</p>"
                + "<p>The Registration Agencies deliver expert services linked with BOWI.</p>"
        },
  			{
          question: "What does the structure and syntax of an BOWI look like?",
          answer: "<p>To know more about BOWI structure and syntax, please got to our Specifications page.</p>" },
  		]
  	}

  	ngOnInit(): void {
      if (window.innerWidth < 768) {
        this.verticalTab = false;
      } else {
        this.verticalTab = true;
      }
  	}

}
