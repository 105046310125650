<div class="row">
	<div class="col-md-10 offset-md-2">
		<h2>Congratulations! Here is your BOWI identifier:</h2>
		<h3 class="text-primary">{{assignResult.formattedBowi || assignResult.bowi}}</h3>
		<div class="mt-3">
			<h4 class="text-decoration-underline">Registrant</h4>
			<dl class="row">
				<dt class="col-md-2"><strong>Name</strong></dt>
			  	<dd class="col-md-10">{{registrant.name}}</dd>
			   	<dt class="col-md-2"><strong>Email</strong></dt>
			  	<dd class="col-md-10">{{registrant.email}}</dd>
			</dl>
		</div>
		<div>
			<h4 class="text-decoration-underline">Musical Work</h4>
			<dl class="row">
				  <dt class="col-md-2"><strong>Title</strong></dt>
			  	<dd class="col-md-10">{{assignResult.workTitle}}</dd>
          <dt class="col-md-2"><strong>Subtitle</strong></dt>
          <dd class="col-md-10">{{assignResult.subtitle}}</dd>
			   	<dt class="col-md-2"><strong>Duration</strong></dt>
			  	<dd class="col-md-10">{{assignResult.durationMs | duration}}</dd>
			   	<dt class="col-md-2"><strong>Year of Creation</strong></dt>
			  	<dd class="col-md-10">{{assignResult.creationDate }}</dd>
			   	<dt class="col-md-2"><strong>ISWC</strong></dt>
			  	<dd class="col-md-10">{{assignResult.otherIds?.iswc}}</dd>
			</dl>
		</div>
		<div>
			<h4 class="text-decoration-underline">Contributors</h4>
			<ul class="list-unstyled" *ngFor="let contributor of assignRequest.contributors">
				<li>
					<span>{{contributor.name.firstname}}</span>&nbsp;<span>{{contributor.name.lastname}}</span>&nbsp;<span>({{contributor.role}})</span><br/>
					<span *ngIf="contributor.isni"><strong>ISNI</strong>: {{contributor.isni}}</span>
					<span *ngIf="contributor.ipi"><strong>IPI</strong>: {{contributor.ipi}}</span>
				</li>
			</ul>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-md-10 offset-md-2">
		<h4>A confirmation email including your BOWI has been sent to {{registrant.email}}</h4>
	</div>
</div>
<div class="row mt-3">
	<div class="col-md-10 offset-md-2">
		<button type="button" class="btn btn-primary" routerLink="/app-assign" [queryParams]="{ reset: true}">Get another BOWI</button>
	</div>
</div>
