<div class="d-inline">
	<button type="button" class="btn" data-bs-toggle="modal" (click)="openModal()">
		<i class="ion ion-ios-help-circle me-2"></i>
	</button>

	<div class="modal fade" id="modal{{modalId}}" tabindex="-1" aria-hidden="true" style="display: none;">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">{{title}}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body m-3">
					<p class="mb-0" [innerHTML]="text"></p>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- [attr.data-bs-target]="'#modal' + modalId" -->