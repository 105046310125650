import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BowiService } from '../bowi.service';
import { ClientService } from '../client.service';

@Component({
  selector: 'app-page-get-a-bowi',
  templateUrl: './page-get-a-bowi.component.html',
  styleUrls: ['./page-get-a-bowi.component.css']
})
export class PageGetABowiComponent implements OnInit {
	  form = new UntypedFormGroup({
      registrationKey: new UntypedFormControl('', Validators.required)
    })
    invalidKey: boolean = false;
    queryInProgress:boolean = false;

  	constructor(
  		private bowiService:BowiService,
      private clientService:ClientService,
  		private router:Router) { }

  	ngOnInit(): void {
  	  if(this.clientService.getToken()) {
        //this.form.controls.registrationKey.setValue(this.bowiService.registrant.registrationKey);
        this.navigateToAssign();
      }
  	}

    isRegistrationKeyEmpty() {
      return this.form.controls['registrationKey'].errors?.['required'] 
        && !this.form.controls['registrationKey'].pristine;
    }


    navigateToAssign() {
      this.router.navigate(['/app-assign'], {queryParams: {reset: true}});
    }

  	onSubmit() {
      if(this.form.invalid) {
        return;
      }

      this.queryInProgress = true;
      this.clientService.lookupClient(this.form.controls['registrationKey'].value)
          .subscribe(
            () => { //success
              this.queryInProgress = false;
              this.invalidKey = false;
              this.navigateToAssign();
            },
            () => { //error
              this.queryInProgress = false;
              this.invalidKey = true;
            }
          )
  	}
}
