
<ul class="nav justify-content-center bg-primary">
  <a class="nav-link" routerLink="/"><img src='assets/images/FULL_DARK.png'></a>
    <li class="nav-item">
      <a class="nav-link" routerLink="/app-bowi-registration">Get a BOWI</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/app-registry">Registry</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="http://api.bowi.io/" target="_blank">API</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/app-partners">Partners</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/app-faq">FAQ</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/app-about">Contact</a>
    </li>
</ul>




