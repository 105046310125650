<div class="container container-md">
  <div class="row mt-3">
    <div class="col-md-12">
      <h1>Registry</h1>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12">

      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="Search the Registry" class="nav-link">
          <app-bowi-search></app-bowi-search>
        </mat-tab>
        <mat-tab label="Download the Registry" class="nav-link">
          <app-bowi-download></app-bowi-download>
        </mat-tab>
      </mat-tab-group>
    
    </div>
  </div>
</div>
