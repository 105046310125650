import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { BowiService } from '../bowi.service';
import { MessageService } from '../message.service';
import {Work, APIResponse, APIError, WorkResult, SearchResult} from '../model';

@Component({
    selector: 'app-bowi-search',
    templateUrl: './bowi-search.component.html',
    styleUrls: ['./bowi-search.component.css']
})
export class BowiSearchComponent implements OnInit {
    titleSubmitted = false;
    contributorSubmitted = false;
    bowiSubmitted = false;
    searchSubmitted = false;

    form: UntypedFormGroup = new UntypedFormGroup({
      bowi: new UntypedFormControl('', []),
      title: new UntypedFormControl('', []),
      contributor: new UntypedFormControl('', [])
    });

    results: any[] = [];


  	constructor(
        private formBuilder: UntypedFormBuilder,
        private bowiService: BowiService,
        private messageService: MessageService) {
  		  this.results = [];
  	}

    invalidBowi(): boolean {
      return this.bowiSubmitted && !this.form.get('bowi')?.valid;
    }

   	invalidTitle(): boolean {
	    return this.titleSubmitted && !this.form.get('title')?.valid;
    }

    invalidContributor(): boolean {
      return this.contributorSubmitted && !this.form.get('contributor')?.valid;
    }

  	ngOnInit(): void {
  	}

  	handleHttpSuccess(data: APIResponse): void {
        this.messageService.pushWorks({works: data.results.works});
    }

    handleHttpError(error: APIError): void{
        this.messageService.pushWorks({works: []});
    }

    searchByBowi(): void {
      this.bowiSubmitted = true;
      if (this.invalidBowi()) { return; }

      this.messageService.pushActivity({queryInProgress: true});
      const bowi = this.form.get('bowi')?.value;
      this.bowiService.lookup(bowi)
          .subscribe((works: WorkResult[]) => {
            this.messageService.pushWorks({works});
          });
    }

    searchMulti(): void {
        this.searchSubmitted = true;

        const params = {
          title: this.form.get('title')?.value,
          contributor: this.form.get('contributor')?.value
        };

        this.messageService.pushActivity({queryInProgress: true});
        this.bowiService.searchMulti(params)
          .subscribe((works: SearchResult) => {
            console.log(works);
            this.messageService.pushWorks(works);
          });
    }
}
