import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { EmailService} from '../email.service';

@Component({
  selector: 'app-page-about',
  templateUrl: './page-about.component.html',
  styleUrls: ['./page-about.component.css']
})
export class PageAboutComponent implements OnInit {
  activeTab:string = "";
  verticalTab:boolean = true;
  contactForm:UntypedFormGroup = new UntypedFormGroup({
    company: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    message: new UntypedFormControl('')
  })
  contactFailed:boolean = false;
  contactSent:boolean = false;

  constructor(private emailService:EmailService,) {
    this.activeTab = "contact";
  }

  ngOnInit(): void {
    if (window.innerWidth < 768) {
      this.verticalTab = false;
    } else {
      this.verticalTab = true;
    }
  }


  onSubmit() {
      if(this.contactForm.get('email')?.errors != null) { return; }

      const data = {
        email: this.contactForm.get("email")?.value,
        name: this.contactForm.get("name")?.value,
        company: this.contactForm.get("company")?.value,
        message: this.contactForm.get("message")?.value
      }
      this.emailService.contactMessage(data)
          .subscribe((data: any) => {
              if(data && data.status == "OK") {
                this.contactSent = true;
                this.contactFailed = false;
              } else {
                this.contactSent = false;
                this.contactFailed = true;
              }
          })
  }

  isFormInvalid() {
    return this.contactForm.get('email')?.errors;
  }

  isEmailEmpty() {
    let element = this.contactForm.get('email');
    return element?.errors?.['required'] && !element?.pristine;
  }

  isEmailFormatInvalid() {
    let element = this.contactForm.get('email');
    return element?.errors?.['email'] && !element?.pristine;
  }

}
