import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import {Work, Activity, WorkResult, SearchResult} from './model';

@Injectable({ providedIn: 'root' })
export class MessageService {
    worksTopic = new Subject<SearchResult|null>();
    activityTopic = new Subject<Activity>();

    pushWorks(result: SearchResult) {
        this.worksTopic.next(result);
        this.activityTopic.next({ queryInProgress: false }); //query completed
    }

    pushActivity(activity: Activity) {
        this.activityTopic.next(activity);
    }

    clearMessages() {
        this.worksTopic.next(null);
    }

    getWorks(): Observable<any> {
        return this.worksTopic.asObservable();
    }

    getActivity(): Observable<any> {
        return this.activityTopic.asObservable();
    }
}
