import { Directive, ElementRef } from '@angular/core';
declare var jQuery: any

@Directive({
  selector: '[datepicker]'
})
export class DatepickerDirective {

  constructor(el: ElementRef) {
  		jQuery(el.nativeElement).daterangepicker({
			  singleDatePicker: true,
        showDropdowns: true,
        minDate: "1600-01-01",
        maxYear: 2021,
			  autoApply: true,
			  locale: {
		      format: 'YYYY-MM-DD'
		    }
		})
  }

}
