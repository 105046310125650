import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PageHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
