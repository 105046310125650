import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BowiLookupComponent } from './bowi-lookup/bowi-lookup.component';
import { BowiSearchComponent } from './bowi-search/bowi-search.component';
import { BowiAssignComponent } from './bowi-assign/bowi-assign.component';
import { BowiAssignSuccessComponent } from './bowi-assign-success/bowi-assign-success.component';
import { BowiAssignMatchComponent } from './bowi-assign-match/bowi-assign-match.component';
import { PageGetABowiComponent } from './page-get-a-bowi/page-get-a-bowi.component';
import { PageBowiAPIComponent } from './page-bowi-api/page-bowi-api.component';
import { PageRegistryComponent } from './page-registry/page-registry.component';
import { PageFaqComponent } from './page-faq/page-faq.component';
import { PageAboutComponent } from './page-about/page-about.component';
import { PageAssignComponent } from './page-assign/page-assign.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { BowiDownloadComponent } from './bowi-download/bowi-download.component';
import { PartnersComponent } from './page-partners/partners.component';
import { BowiRegistrationComponent } from './bowi-registration/bowi-registration.component';

const routes: Routes = [
	{ path: '', 			component: PageHomeComponent },
	{ path: 'app-get-a-bowi', 	component: PageGetABowiComponent },
	{ path: 'app-bowi-api', 	  component: PageBowiAPIComponent },
	{ path: 'app-registry', 	  component: PageRegistryComponent },
	{ path: 'app-download',     component: BowiDownloadComponent },
	{ path: 'app-faq', 			    component: PageFaqComponent },
	{ path: 'app-about', 		    component: PageAboutComponent },
	{ path: 'app-assign', 		  component: PageAssignComponent },
	{ path: 'app-assign-success', component: BowiAssignSuccessComponent },
	{ path: 'app-assign-match', component: BowiAssignMatchComponent },
	{ path: 'app-partners', component: PartnersComponent },
	{ path: 'app-bowi-registration', component: BowiRegistrationComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
