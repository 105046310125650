import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'creationDate'
})
export class CreationDatePipe implements PipeTransform {

  transform(creationDate: string): string {
    if (creationDate == null || creationDate == "") {
      return "-";
    } else {
      if(creationDate.indexOf("[UTC]") > -1) {
        creationDate = creationDate.substr(creationDate.indexOf("[UTC]"), 5);
      }
      return dayjs(creationDate).format("YYYY-MM");
    }
  }

}
