<div class="row">
	<div class="col-md-10 offset-md-2">
		<h2>Possible Duplicates</h2>
	</div>
</div>
<div class="row">
	<div class="col-md-8 offset-md-2">
		<h4>We have found the following records as being potential duplicates of your Work.
			If one of them is the Work you wanted to register, the job is already done.
			If not, select the “I correct my data” button below.</h4>
	</div>
</div>

<div *ngFor="let match of matches" class="row mt-3">
	<div class="col-md-6 offset-md-2">

		<app-bowi-work-card [work]="match"></app-bowi-work-card>
	</div>
</div>

<div class="row mt-3">
	<div class="col-md-3 offset-md-2">
		<button type="button" class="btn btn-primary btn-lg" routerLink="/app-assign" [queryParams]="{ reset: false}">I correct my data</button>
	</div>
	<div class="col-md-3 ">
		<button type="button" class="btn btn-primary btn-lg" routerLink="/app-assign" [queryParams]="{ reset: true}">Generate a new BOWI</button>
	</div>
</div>
