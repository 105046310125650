import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { BowiService } from '../bowi.service';
import { MessageService } from '../message.service';
import {Work, APIResponse, APIError, WorkResult, SearchResult} from '../model';

@Component({
    selector: 'app-bowi-lookup',
    templateUrl: './bowi-lookup.component.html',
    styleUrls: ['./bowi-lookup.component.css']
})
export class BowiLookupComponent {

	  submitted = false;
	  registered = false;
    bowi = '';
    bowiFormatRegex = /B{1}[0-1]{1}-?[0-9a-zA-Z]{7}[0-9]{1}-?[0-9a-zA-Z]{1,2}/;

    result!: WorkResult;

	  constructor(
        private formBuilder: UntypedFormBuilder,
        private bowiService: BowiService,
        private messageService: MessageService
    ) {
        this.bowi = '';
    }

	  isBowiEmpty(): boolean {
	      return this.submitted && this.bowi === '';
    }

    isBowiInvalidFormat(): boolean {
        return this.submitted && this.bowi !== '' && !this.bowiFormatRegex.test(this.bowi);
    }

    isResultUndefined(): boolean {
      return this.result === null || typeof this.result === 'undefined';
    }

    handleHttpSuccess(data: APIResponse): void {
        console.log('handleHttpSuccess');
        console.log(data.results.works);
        // this.messageService.pushWorks(data.results.works as SearchResult);
        this.result = data.results.works[0];
    }

    handleHttpError(error: APIError): void {
        console.log('handleHttpError');
        this.messageService.pushWorks({works: []});
    }

    onSubmit(): void
    {
        this.submitted = true;
        if(this.isBowiEmpty() || this.isBowiInvalidFormat()) { return; }

        this.messageService.pushActivity({queryInProgress: true});
        this.bowiService.lookup(this.bowi)
            .subscribe((data: any) => {
              console.log("subscribe");
                // if(data && data.status === 'OK') {
                //   this.result = data.results.works[0];
                // } else {
                //   this.handleHttpError(data as APIError);
                // }
            });
    }
}
