<div class="api-results overflow-auto" style="height: 800px">
	<div *ngIf="isQueryInError">
		<p class="text-danger">{{errorMessage}}</p>
	</div>

	<div *ngIf="isResultsEmpty()">
		<p class="text-info">No results</p>
	</div>

  <div class="row" *ngIf="isQueryInProgress">
    <div class="col-4">
      <span>Loading...</span>
      <div class="spinner-border text-dark me-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <div *ngIf="rows.length > 0">
    <div>
      <h4>Results displayed : {{rows.length}}</h4>
      <em *ngIf="totalWorks > rows.length">Total works in the database: {{totalWorks}}. Refine your search if possible.</em>
      <table class="table" style="width: 100%">
        <thead>
          <tr>
            <th>
              <div class="filter">
                <span>BOWI</span>
              </div>
            </th>
            <th>
              <div class="filter">
              <span>Title</span>
              </div>
            </th>
            <th>
              <div class="filter">
              <span>Contributors</span>
              </div>
            </th>
            <th>
              <div class="filter">
              <span>ISWC</span>
              </div>
            </th>
            <th>
              <div class="filter">
                <span>Creation date</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let row of rows; let idx = index">
            <tr class="workrow">
              <td>{{row.formattedBowi}}</td>
              <td>
                {{row.workTitle}}
                <ng-container *ngIf="row.subtitle"><em style="font-size: 0.8em; display: block">{{row.subtitle}}</em></ng-container>
              </td>
              <td>
                <ul class="list-unstyled">
                  <li *ngFor="let contrib of row.contributors">
                    <ng-container *ngIf="contrib.name">{{contrib.name.firstname}} {{contrib.name.lastname}}</ng-container>
                  </li>
                </ul>
  <!--              <a href="#" onclick="event.preventDefault();event.target.parentElement.parentElement.nextElementSibling.classList.toggle('hide');">see more</a>-->
                <button (click)="toggleDetailsRow(idx)" type="button" class="btn btn-link toggleLink" #toggleLink>see more</button>
              </td>
              <td>{{row.otherIds?.['iswc'] | iswc}}</td>
              <td>{{row.creationDate}}</td>
            </tr>
            <tr class="subrow hide" id="subrow-{{idx}}" #subrow>
              <td colspan="5">
                <div class="grid">
                  <div><strong>Name</strong></div><div><strong>Role</strong></div><div><strong>IPI</strong></div><div><strong>ISNI</strong></div>
                  <ng-container *ngFor="let contrib of row.contributors">
                    <div>{{contrib.name.firstname}} {{contrib.name.lastname}}</div>
                    <div>{{contrib.role}}</div>
                    <div>{{contrib.ipi}}</div>
                    <div>{{contrib.isni | isniFmt}}</div>
                  </ng-container>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
