import { Component, OnInit } from '@angular/core';

import { WorkResult } from '../model';
import { BowiService} from '../bowi.service';

@Component({
  selector: 'app-bowi-assign-match',
  templateUrl: './bowi-assign-match.component.html',
  styleUrls: ['./bowi-assign-match.component.css']
})
export class BowiAssignMatchComponent implements OnInit {
	matches:WorkResult[];

  	constructor(private bowiService:BowiService) { 
  		this.matches = [];
  	}

	ngOnInit(): void {
		const assignResults = this.bowiService.getAssignResults();
  		if(assignResults != null && assignResults.length > 0){
  			this.matches = assignResults;
  		}
	}

}
