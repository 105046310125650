import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isniFmt'
})
export class IsniPipe implements PipeTransform {
  isniRegex = /^([0-9]{4})([0-9]{4})([0-9]{4})([0-9]{4})$/;

  transform(value: string|undefined): string {
    if (typeof(value) === 'undefined') { return ''; }
    const r: any = this.isniRegex.exec(value) || [];
    if(r.length === 5){
      return r[1]+' '+r[2]+' '+r[3]+' '+r[4]; // /0123 4567 8945 6321
    } else {
      return value;
    }
  }

}
