import { Component, OnInit } from '@angular/core';

import { BowiService} from '../bowi.service';

@Component({
  selector: 'app-bowi-assign-success',
  templateUrl: './bowi-assign-success.component.html',
  styleUrls: ['./bowi-assign-success.component.css']
})
export class BowiAssignSuccessComponent implements OnInit {
	assignRequest:any;
	assignResult:any;
	registrant:any;

  	constructor(private bowiService:BowiService) {
  		
  	}

  	ngOnInit(): void {
  		const defaultRequest = {
  			work: {},
  			registrant: {},
  			contributors: [] as any[]
  		}

  		const assignResults = this.bowiService.getAssignResults();
  		if(assignResults != null && assignResults.length > 0){
  			this.assignResult = assignResults[0];
  		}

  		const assignRequestStr = sessionStorage.getItem('assignRequest') || "";
  		this.assignRequest = assignRequestStr != "" ? JSON.parse(assignRequestStr) : defaultRequest;

  		const registrantStr = sessionStorage.getItem('registrantInfo') || "";
  		this.registrant = registrantStr != "" ? JSON.parse(registrantStr) : {};
  	}	

}
