import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormGroup, UntypedFormControl, FormBuilder, Validators } from '@angular/forms';

import { BowiService } from '../bowi.service';

@Component({
  selector: 'app-bowi-download',
  templateUrl: './bowi-download.component.html',
  styleUrls: ['./bowi-download.component.css']
})
export class BowiDownloadComponent implements OnInit {
    downloadForm = new UntypedFormGroup({
        company: new UntypedFormControl('', Validators.required),
        name: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        intendedUse: new UntypedFormControl(''),
        acceptConditions: new UntypedFormControl('', Validators.requiredTrue)
    });
    emailFailed:boolean = false;
	  emailSent:boolean = false;
    queryInProgress:boolean = false;

  	constructor(private bowiService:BowiService) { }

  	ngOnInit(): void {

  	}

  	isCompanyInvalid() {
        let element = this.downloadForm.get('company');
        return element?.errors && !element?.pristine;
  	}

  	isNameInvalid() {
        let element = this.downloadForm.get('name');
        return element?.errors && !element?.pristine;
  	}

  	isEmailInvalid() {
        let element = this.downloadForm.get('email');
        return element?.errors && !element?.pristine;
  	}

  	isEmailEmpty() {
        let element = this.downloadForm.get('email');
        return element?.errors?.['required'] && !element?.pristine;
  	}

  	isEmailFormatInvalid() {
        let element = this.downloadForm.get('email');
        return element?.errors?.['email'] && !element?.pristine;
  	}

  	areConditionsNotAccepted() {
  		let element = this.downloadForm.get('acceptConditions');
  		return element?.value !== true && !element?.pristine;
  	}

  	isFormInvalid() {
      	return this.downloadForm.invalid || this.downloadForm.get('acceptConditions')?.value !== true;
  	}

  	onSubmit() {
      this.queryInProgress = true;

  		const downloadRequest = {
  		  name: this.downloadForm.controls['name'].value,
        email: this.downloadForm.controls['email'].value,
        company: this.downloadForm.controls['company'].value,
        intendedUse: this.downloadForm.controls['intendedUse'].value
      }
      this.bowiService.downloadRegistry(downloadRequest)
        .subscribe((data:any) => {
            this.queryInProgress = false;
            if(data.status == "OK") {
              this.emailSent = true;
              this.emailFailed = false;
            } else {
              this.emailFailed = true;
              this.emailSent = false;
            }
        })
  	}
}
