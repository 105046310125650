<div *ngIf="!emailSent">
  <div class="row">
    <div class="col-md-10">
      <p>The full BOWI registry is available as a Weekly dump. It is free of charge and available under the Creative Commons Attribution-NonCommercial 4.0 International</p>
      <p>To receive a copy of the registry, please fill the form below. You will receive an email that will guide you through the downloading process.</p>
    </div>
  </div>
  <form [formGroup]="downloadForm">
    <dl class="row mt-3">
      <dd class="col-md-2 text-end"><label class="col-form-label">Company<span class="text-danger">&nbsp;*</span></label></dd>
      <dt class="col-md-4">
        <input type="text" class="form-control"  aria-label="company" aria-describedby="company"
        formControlName="company"
        [ngClass]="{ 'is-invalid': isCompanyInvalid() }">
        <label id="validation-company-error" class="error small form-text invalid-feedback" *ngIf="isCompanyInvalid()">This field is required.</label>
      </dt>
      <div class="6"></div>

      <dd class="col-md-2 text-end"><label class="col-form-label">Name<span class="text-danger">&nbsp;*</span></label></dd>
      <dt class="col-md-4">
        <input type="text" class="form-control"  aria-label="name" aria-describedby="name"
        formControlName="name"
        [ngClass]="{ 'is-invalid': isNameInvalid() }">
        <label id="validation-name-error" class="error small form-text invalid-feedback" *ngIf="isNameInvalid()">This field is required.</label>
      </dt>
      <div class="6"></div>

      <dd class="col-md-2 text-end"><label class="col-form-label">Email<span class="text-danger">&nbsp;*</span></label></dd>
      <dt class="col-md-4">
        <input type="text" class="form-control"  aria-label="email" aria-describedby="email"
        formControlName="email"
        [ngClass]="{ 'is-invalid': isEmailInvalid() }">
        <label id="validation-name-error" class="error small form-text invalid-feedback" *ngIf="isEmailInvalid() && isEmailEmpty()">This field is required.</label>
        <label id="validation-name-error" class="error small form-text invalid-feedback" *ngIf="isEmailInvalid() && isEmailFormatInvalid()">This field must follow a standard email format.</label>
      </dt>
      <div class="6"></div>

      <dd class="col-md-2 text-end"><label class="col-form-label">Intended use</label></dd>
      <dt class="col-md-4">
        <textarea class="form-control"  aria-label="intendedUse" aria-describedby="intendedUse"
        formControlName="intendedUse"></textarea>
      </dt>
      <div class="6"></div>

      <dd class="col-md-2 text-end"></dd>
      <dt class="col-md-6 mt-3">
        <input type="checkbox" class="form-check-input"  formControlName="acceptConditions">
        <label class="form-label ms-3" [ngClass]="{ 'is-invalid': areConditionsNotAccepted() }">I accept the <a href="#" data-bs-toggle="modal" [attr.data-bs-target]="'#modalTermsAndConditions'">terms and conditions</a></label>
        <label id="validation-name-error" class="error small form-text invalid-feedback" *ngIf="areConditionsNotAccepted()">It is mandatory to accept the terms and conditions</label>
        <div class="modal fade" id="modalTermsAndConditions" tabindex="-1" aria-hidden="true" style="display: none;">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Terms and Conditions</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body m-3" style="overflow-y: scroll; height: 300px;">
                <p class="text-end">Last Updated: February 1st 2021</p>
                <p>This Terms of Use ("Agreement") describes conditions under which Quansic ("Quansic"), as the BOWI International Agency, allows the public to access and use the BOWI registry ("Registry").</p>
                <p>When you access and use BOWI Registry, you agree to the terms and conditions of this Agreement. If you do not agree to the terms and conditions, you should not download the Registry.</p>
                <strong>Use of the BOWI registry</strong>
                <p>Provided that you comply with this Agreement, QUANSIC, as the BOWI International
                Agency, hereby grants you a non-exclusive, non-transferable, Attribution - NonCommercial -
                NoDerivatives 4.0 International (CC BY-NC-ND 4.0) license to access, display, and use the
                BOWI registry in order to lookup for metadata related to a BOWI or identify the BOWI related
                  to a given musical work ("Purpose").</p>
                <p>You undertake not to access, display and use the BOWI registry for any purpose other than
                  the Purpose defined in this Agreement.</p>
                <p>You understand and agree that information obtained from the BOWI registry may not be
                sold, offered for sale, marketed, promoted, advertised, commercialized or used for any other
                purpose, for instance, as the basis to create derivative works, directly or indirectly, in any
                  manner.</p>
                <p>You agree not to reproduce, modify, create derivative works from, display, publish, distribute,
                disseminate, broadcast or circulate any content found on the BOWI registry to any third party
                (including, without limitation, the display and distribution of such content via a third-party
                website). You further agree that you will not disassemble, decompile, reverse engineer or
                  otherwise modify the content in any way at any time.</p>
                <p>You agree to access and use the BOWI website only for lawful purposes, and that you are
                responsible for your access and use of the BOWI registry. You recognize that BOWI registry
                may contain personally identifiable information ("Personal Data"), and you agree that you will
                not use any such Personal Data for any purpose other than the Purpose. You understand
                and agree that these Personal Data may therefore be governed by data protection law which
                may interfere with the permitted uses contemplated herein and that, upon request by
                QUANSIC, you will refrain from using such Personal Data in any manner and for any
                  purpose.</p>
                <strong>Disclaimer</strong>
                <p>Your use of the BOWI registry is at your sole risk. The BOWI registry is provided on an "as
                is" and "as available" basis. QUANSIC, as the BOWI International Agency, and its appointed
                registration agencies do not take over responsibility for the correctness and accuracy of the
                provided data nor warranty or indemnify the user for any damage, which may result either
                  directly or indirectly from information, which the user retrieved from BOWI registry.</p>
                <p>Further, neither QUANSIC or its service provider warrant that access to or use of the BOWI
                registry will be uninterrupted or error-free, that defects will be corrected, that the BOWI
                registry or the server that makes it available are free of viruses or other harmful components
                or that information accessible via the BOWI registry will be complete, accurate, or timely. To
                the fullest extent permissible under applicable law, you understand and agree that neither
                QUANSIC or its service provider shall not be liable for any direct, indirect, incidental, special,
                consequential, punitive, exemplary or any other damages relating to or resulting from your
                use or inability to use the BOWI registry or from any actions QUANSIC or its service provider
                takes or fails to take as a result of electronic mail messages and/or other electronic
                submissions you send QUANSIC or its service provider. These include damages relating to
                errors, omissions, down time, interruptions, defects, delays, computer viruses, your loss of
                profits, loss of data, unauthorized access to and alteration of your transmissions and data,
                and other tangible and intangible losses. This limitation applies regardless of whether the
                damages are claimed under the terms of a contract, as the result of negligence or otherwise,
                and even if QUANSIC or its service provider or their representatives have been negligent or
                  have been advised of the possibility of such damages.</p>
                <p>QUANSIC reserves the right at any time and from time to time to modify or discontinue,
                temporarily or permanently, the BOWI registry (or any part thereof) with or without notice.
                You agree that QUANSIC shall not be liable to you or to any third party for any modification,
                  suspension or discontinuation of the BOWI registry.</p>
                <strong>Changes to this Agreement</strong>
                <p>QUANSIC reserves the right, at its sole discretion, to change, modify or otherwise alter these
                terms and conditions at any time. Such changes and/or modifications shall become effective
                immediately upon the posting thereof. The "Last Updated" date at the top of this Agreement
                will indicate when the latest modifications were made. Please review the terms and
                conditions periodically. Your continued use of the BOWI website following the posting of
                changes and/or modifications will constitute your acceptance of the revised terms and
                  conditions.</p>

                <strong>Applicable laws</strong>
                <p>When you access and use the BOWI registry or any data made available via the BOWI
                website, you are responsible for compliance with applicable laws. Any claim relating to the
                  BOWI shall be governed by the Swiss law.</p>
                <strong>Contact</strong>
                <p>If you have any additional questions about this Agreement or the BOWI, please contact
                  QUANSIC at contact&#64;quansic.com</p>

<!--                <p class="mb-0"> Welcome to the BOWI International Agency, home of BOWI!</p>-->
<!--                <p>BOWI is the OPEN identifier for all the musical works.</p>-->
<!--                <p>Whether you are a songwriter, a composer, a publisher, a record label, a distributor or a DSP, by assigning a BOWI to a musical work you optimize its identification, its traceability and ultimately its exploitation</p>-->
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </dt>
      <div class="6"></div>
    </dl>
    <div class="row mt-3">
      <div class="col-md-6 text-center">
        <button *ngIf="!queryInProgress" type="button" class="btn btn-primary btn-lg" (click)="onSubmit()" [attr.disabled]="isFormInvalid()? true: null">Download</button>
        <label class="text-danger" *ngIf="emailFailed">A problem happened while preparing the email that contains the link to the registry. Please try again later.</label>
        <div *ngIf="queryInProgress" class="spinner-border text-primary me-2" role="status"></div>
      </div>
      <div class="6"></div>
    </div>
  </form>
</div>
<div *ngIf="emailSent">
  <div class="row">
    <div class="col-md-6">
      <p class="h3">Success !</p>
    </div>
    <div class="col-md-6"></div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <strong>An email has been sent to {{downloadForm.get('email')?.value}} with a link to the registry.</strong>
    </div>
    <div class="col-md-6"></div>
  </div>
</div>

<!-- Mettre un "thank you, an email has been sent to {{email}} with a link to download the registry"
a la place du form en entier.  -->
