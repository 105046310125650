<div class="container container-lg">
	<div class="row mt-3">
		<div class="col col-lg-12">
			<h1>BOWI API</h1>
		</div>

		<div class="col col-lg-8">
			<p>All the key functionalities of BOWI are available through a set of APIs.</p>
			<ul>
				<li>BOWI search and lookup</li>
				<li>BOWI Assign</li>
				<li>BOWI registry download</li>
			</ul>
			<p>Visit our <a hlink="#" class="link-primary">API center</a> to learn more.</p>
		</div>
	</div>
</div>