<form [formGroup]="form" (keydown.enter)="$event.preventDefault()" >
  <div class="row mt-3">
    <div class="col-md-3">
      <p class="h3">Lookup by BOWI</p>
    </div>
    <div class="col-md-6">
      <div class="form-group" >
        <!-- <label for="title" class="control-label"><h3></h3></label> -->
        <input type="text" class="form-control" required formControlName="bowi" [ngClass]="{ 'is-invalid': invalidBowi() }"/>
        <p *ngIf="invalidBowi()" id="bowi_error" class="invalid-feedback">A bowi is required</p>
      </div>
    </div>
    <div class="col-md-3">
        <button type="button" class="btn btn-primary" (click)="searchByBowi()">Search by BOWI</button>
    </div>
  </div>

  <hr/>

  <div class="row mt-3">
    <div class="col-md-12">
      <p class="h3">Search</p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-3" style="text-align: end">
      Work Title
    </div>
    <div class="col-md-6">
      <div class="form-group" >
        <!-- <label for="title" class="control-label"><h3></h3></label> -->
        <input type="text" class="form-control" required formControlName="title" [ngClass]="{ 'is-invalid': invalidTitle() }"/>
        <p *ngIf="invalidTitle()" id="title_error" class="invalid-feedback">A title is required</p>
      </div>
    </div>
    <div class="col-md-3">
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-3" style="text-align: end">
      Work Contributors
    </div>
    <div class="col-md-6">
      <div class="form-group" >
        <!-- <label for="title" class="control-label"><h3></h3></label> -->
        <input type="text" class="form-control" required formControlName="contributor" [ngClass]="{ 'is-invalid': invalidContributor() }"/>
        <p *ngIf="invalidContributor()" id="contrib_error" class="invalid-feedback">A contributor name is required</p>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <button type="button" class="btn btn-primary" (click)="searchMulti()">Search</button>
      </div>
    </div>
  </div>



<!--  <div class="row mt-3">-->

<!--    <div class="col-md-4">-->
<!--      <p class="h3">Search by Work title</p>-->
<!--    </div>-->
<!--    <div class="col-md-6">-->
<!--        <div class="form-group" >-->
<!--          &lt;!&ndash; <label for="title" class="control-label"><h3></h3></label> &ndash;&gt;-->
<!--          <input type="text" class="form-control" placeholder="by work title" id="title" required [(ngModel)]="title" name="title" [ngClass]="{ 'is-invalid': invalidTitle() }"/>-->
<!--          <p *ngIf="invalidTitle()" id="title_error" class="invalid-feedback">A title is required</p>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class="col-md-2">-->
<!--      <div class="form-group">-->
<!--        <button type="button" class="btn btn-primary" (click)="searchByTitle()">Search</button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="row mt-3">-->
<!--    <div class="col-md-4">-->
<!--      <p class="h3">Search by Contributor Name</p>-->
<!--    </div>-->
<!--    <div class="col-md-6">-->
<!--      <div class="form-group" >-->
<!--        &lt;!&ndash; <label for="title" class="control-label"><h3></h3></label> &ndash;&gt;-->
<!--        <input type="text" class="form-control" placeholder="by contributor" id="contributor" required [(ngModel)]="contributor" name="contributor" [ngClass]="{ 'is-invalid': invalidContributor() }"/>-->
<!--        <p *ngIf="invalidContributor()" id="contributor_error" class="invalid-feedback">A contributor's name is required</p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-md-2">-->
<!--      <div class="form-group">-->
<!--        <button type="button" class="btn btn-primary" (click)="searchByContributor()">Search</button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</form>
<div class="row mt-3">
  <div class="col">
    <hr/>
  </div>
</div>

<div class="row mt-3">
  <div class="col">
    <app-bowi-results></app-bowi-results>
  </div>
</div>




<!-- <app-bowi-results [results]="results"></app-bowi-results> -->
