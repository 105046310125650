import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';

import { BowiService} from '../bowi.service';
import { MessageService } from '../message.service';
import { AssignmentRequest, AssignmentResponse, Work, Registrant, APIResponse } from '../model';
import {ClientService} from "../client.service";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";

declare var $: any;
declare var bootstrap: any;

let ContributorAdditionalIdValidator: ValidatorFn = (contributorFg: AbstractControl)  => {
  const isni = contributorFg.get('isni')?.value;
  const ipi = contributorFg.get('ipi')?.value;
  const isIsniEmpty = isni == null || isni == "";
  const isIpiEmpty = ipi == null || ipi == "";
  return isIsniEmpty && isIpiEmpty
    ? { isniOrIpiRequired: true }
    : null;
};

@Component({
    selector: 'app-bowi-assign',
    templateUrl: './bowi-assign.component.html',
    styleUrls: ['./bowi-assign.component.css']
})
export class BowiAssignComponent implements OnInit {
	  submitted = false;
    activeTab:string = 'work';
    reset:boolean = true;
    queryInProgress:boolean = false;
    errorMessage:string = "";
    verticalTab:boolean = true;

    contributorRoleList:any = [
      { key: "ComposerLyricist", value: "Composer / Lyricist"},
      { key: "Lyricist", value: "Lyricist" },
      { key: "Composer", value: "Composer" },
      { key: "Arranger", value: "Arranger" },
      { key: "Adapter", value: "Adapter" }
    ];
    nameTypeList:any = [
      { key: "realName", value: "Real name"},
      { key: "pseudonym", value: "Pseudonym"}
    ];

    registrant:Registrant = { name: "", email: "", registrationKey: "" };

    assignmentRequestForm = new UntypedFormGroup({
      isProdMode: new UntypedFormControl(false),
      work: new UntypedFormGroup({
          title: new UntypedFormControl('', [Validators.required, Validators.maxLength(1024)]),
          subtitle: new UntypedFormControl(''),
          duration: new UntypedFormControl('', [Validators.pattern(/((?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d)/)]), //\\d{2}:\\d{2}:\\d{2}
          yearOfCreation: new UntypedFormControl('', [Validators.pattern('\\d{3,4}(-(0[1-9]|1[012]))?(-(0[1-9]|[12][0-9]|3[01]))?')]), //"\\d{4}(-[1-9]|1[012])(-\\d{1,2})?")]),     -(0[1-9]|[12][0-9]|3[01])?
          otherID: new UntypedFormGroup({
            type: new UntypedFormControl('ISWC'),
            value: new UntypedFormControl('', [Validators.pattern(/T[0-9]{10}/)])
         })
      }),
      contributors: new UntypedFormArray([])
    });
  	contributors = this.assignmentRequestForm.get('contributors') as UntypedFormArray;

    get f() { return this.assignmentRequestForm.controls; }

  	constructor(
        private bowiService:BowiService,
        private clientService:ClientService,
        private messageService: MessageService,
        private router: Router,
        private route:ActivatedRoute) {
  	}

  	ngOnInit(): void {
      this.activeTab = "work";

      this.populateRegistrantInfo();

      this.checkResetParam();
      this.initNextStepButton();

      //Set tab orientation based on screen real estate available
      if (window.innerWidth < 768) {
        this.verticalTab = false;
      } else {
        this.verticalTab = true;
      }
    }

    initNextStepButton() {
      const nextStep = $('#next-step');
      nextStep.click(() => {
        var someTabTriggerEl = document.querySelector(".nav-tabs a[href='#tab-contributors']")
        var tab = new bootstrap.Tab(someTabTriggerEl)
        tab.show();
      })
    }

    checkResetParam() {
        this.route.queryParams.subscribe(params => {
            this.reset = typeof(params['reset']) !== "undefined" ? JSON.parse(params['reset']): false;
            if(this.reset){
                this.addCreator();
            } else {
                this.populateAssignmentRequestForm();
            }
        });
    }

    populateAssignmentRequestForm() {
        const requestStr = sessionStorage.getItem("assignRequest");
        if(requestStr != null && requestStr != "") {
            const request: AssignmentRequest = JSON.parse(requestStr);
            if(request != null){
                this.f['work']?.get('title')?.setValue(request.work?.title);
                this.f['work']?.get('subtitle')?.setValue(request.work?.subtitle);
                this.f['work']?.get('duration')?.setValue(request.work?.duration);
                this.f['work']?.get('yearOfCreation')?.setValue(request.work?.yearOfCreation);
                this.f['work']?.get('otherID')?.get('type')?.setValue("ISWC");
                this.f['work']?.get('otherID')?.get('value')?.setValue(request.otherIds?.iswc || "");

                request.contributors.forEach((contributor) => {
                    this.contributors.push(new UntypedFormGroup({
                        firstName: new UntypedFormControl(contributor.name.firstname),
                        lastName: new UntypedFormControl(contributor.name.lastname, Validators.required),
                        nameType: new UntypedFormControl(contributor.name.nametype),
                        role: new UntypedFormControl(contributor.role),
                        isni: new UntypedFormControl(contributor.isni, Validators.pattern("\\d{15}[0-9Xx]")),
                        ipi: new UntypedFormControl(contributor.ipi)
                    }, [ContributorAdditionalIdValidator]));
                });
            }
        }
    }

    navigateToGetABowi() {
      this.router.navigateByUrl('/app-get-a-bowi');
    }

    populateRegistrantInfo() {
        const registrant = this.clientService.registrant;
        if(registrant) {
            this.registrant = {
                name: registrant.name,
                email: registrant.email,
                registrationKey: registrant.registrationKey
            }
        } else {
          this.navigateToGetABowi();
        }
    }

    setActiveTab(activeTab: string): void {
      this.activeTab = activeTab;
    }

  	addCreator(): void {
  		this.contributors.push(new UntypedFormGroup({
          firstName: new UntypedFormControl(''),
          lastName: new UntypedFormControl('', Validators.required),
          nameType: new UntypedFormControl('realName'),
          role: new UntypedFormControl('ComposerLyricist'),
          isni: new UntypedFormControl('', Validators.pattern("\\d{15}[0-9Xx]")),
          ipi: new UntypedFormControl('', Validators.pattern("\\d{11}"))
      }, [ContributorAdditionalIdValidator]));
  	}

  	removeCreator(index: number): void {
  		this.contributors.removeAt(index);
  	}

    buildAssignementRequest(): AssignmentRequest {
      var request:AssignmentRequest = {
        workTitle: this.assignmentRequestForm.get('work')?.get('title')?.value,
        workSubtitle: this.assignmentRequestForm.get('work')?.get('subtitle')?.value,
        otherIds: {
          iswc: this.assignmentRequestForm.get('work')?.get('otherID')?.get('value')?.value
        },
        creationDate: this.assignmentRequestForm.get('work')?.get('yearOfCreation')?.value,
        durationMs : dayjs(this.assignmentRequestForm.get('work')?.get('duration')?.value).millisecond(),
        work: {
            title: this.assignmentRequestForm.get('work')?.get('title')?.value,
            subtitle: this.assignmentRequestForm.get('work')?.get('subtitle')?.value,
            duration: this.assignmentRequestForm.get('work')?.get('duration')?.value,
            yearOfCreation: this.assignmentRequestForm.get('work')?.get('yearOfCreation')?.value
        },
        contributors: [],
        registrant: this.registrant
      }


      this.contributors.value.forEach((item:any) => {
        request.contributors.push({
          name: {
            firstname: item.firstName,
            lastname: item.lastName,
            nametype: item.nameType
          },
          isni: item.isni !== '' ? item.isni: undefined,
          ipi: item.ipi !== '' ? item.ipi: undefined,
          role: item.role
        });
      });
      return this.sanitizeAssignmentRequest(request);
    }

    sanitizeAssignmentRequest(request:AssignmentRequest) {
      if(request.creationDate === '') {
          delete request.creationDate;
      }
      if(request.workSubtitle === '') {
        delete request.workSubtitle;
      }
      if(request.otherIds.iswc === '') {
        delete request.otherIds.iswc;
      }
      request.contributors.forEach((contributor) => {
          if(contributor.name.firstname === '') {
              delete contributor.name.firstname;
          }
          if(contributor.isni === '') {
            delete contributor.isni;
          }
          if(contributor.ipi === '') {
            delete contributor.ipi;
          }
      })
      return request;
    }

    validateDuplicateContributor() {

    }

  	onSubmit() {
  		let request:AssignmentRequest = this.buildAssignementRequest();
      sessionStorage.setItem('assignRequest', JSON.stringify(request));
      sessionStorage.setItem('registrantInfo', JSON.stringify(this.registrant));

      this.queryInProgress = true;
	  	this.bowiService.assign(request)
          .pipe(
            catchError(error => {
              console.log("in catch error");
              console.log(error);
              this.queryInProgress = false;
              this.errorMessage = "There has been an error when creating the BOWI: the assignment is not authorized. <br>Please click on \"Get a BOWI\" in the menu bar and enter your registration key again.";
              return throwError("Unauthorized")
            })
          )
	  			.subscribe((data: APIResponse) => {
            console.log("response from bowi service")
            console.log(data);
            this.queryInProgress = false;

            if(data.status == "OK") {
                console.log(data.status);
                console.log(data);
                this.bowiService.setAssignResults(data.results.works);
                this.router.navigateByUrl('/app-assign-success');
            }
            if(data.status == "KO") {
                console.log("Assign error");
                console.log(data.message);
                this.queryInProgress = false;
                this.errorMessage = "There has been an error when creating the BOWI: ";
                if(data.message) {
                    this.errorMessage += encodeURIComponent(data.message);
                } else {
                    this.errorMessage += "Please try again later";
                }
            }
            if(data.status == "MATCH") {
                console.log(data.status);
                console.log(data);
                this.bowiService.setAssignResults(data.results.works);
                this.router.navigateByUrl('/app-assign-match');
            }
	  	    })
  	}

    isFormInvalid() {
      return this.f['work'].invalid || this.f['contributors'].invalid;
    }

    isWorkTitleInvalid() {
        let element = this.f['work']?.get('title');
        return element?.errors && !element?.pristine;
    }

    isWorkDurationInvalid() {
        let element = this.f['work']?.get('duration');
        return element?.errors && !element?.pristine;
    }

    isWorkDurationInvalidFormat(){
        let element = this.f['work']?.get('duration');
        return element?.errors?.['pattern'] && !element?.pristine
    }

    isWorkYearOfCreationInvalid() {
        let element = this.f['work']?.get('yearOfCreation');
        return element?.errors && !element?.pristine;
    }

    isISWCInvalid() {
      let element = this.f['work']?.get('otherID')?.get('value');
      return element?.errors && !element?.pristine;
    }

    isContributorsLastNameInvalid(idx:number) {
        let element = this.contributors.at(idx).get('lastName');
        return element?.errors && !element?.pristine;
    }

    isContributorsIsniInvalid(idx: number) {
        let element = this.contributors.at(idx).get('isni');
        return element?.errors && !element?.pristine;
    }

    isContributorsIpiInvalid(idx: number) {
      let element = this.contributors.at(idx).get('ipi');
      return element?.errors && !element?.pristine;
    }

    isContributorsAdditionalIdRequired(idx:number): boolean|null {
        let element = this.contributors.at(idx);
        return element.errors?.['isniOrIpiRequired'] && !element?.pristine;
    }

    onToggleProdMode(): void {
        this.bowiService.prodMode = this.f['isProdMode'].value;
        if(this.f['isProdMode'].value){
          $('#prodModeModal').modal('show');
        }
    }

    turnOffProdMode(): void {
        this.bowiService.prodMode = false;
        this.f['isProdMode'].setValue(false);
    }

}
