import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iswc'
})
export class IswcPipe implements PipeTransform {
  iswcRegex: RegExp = /T([0-9]{3})([0-9]{3})([0-9]{3})([a-zA-Z0-9]{1})/;
  transform(iswc: string|undefined): string {
    if (iswc === null || typeof iswc === 'undefined') { return ''; }
    const match = iswc.match(this.iswcRegex);
    if(match === null) { return iswc; }
    return `T-${match[1]}.${match[2]}.${match[3]}.${match[4]}`;
  }
}
