import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(milliseconds: number): string {
    if(milliseconds == null) milliseconds = 0;
    if(milliseconds == 0) return "-";

    const duration = dayjs(milliseconds);
    return duration.hour().toString().padStart(2, '0') + ":" + duration.minute().toString().padStart(2, '0') + ":" + duration.second().toString().padStart(2, '0');
  }

}
