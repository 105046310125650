import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from './../environments/environment';

import {Observable, of, throwError} from "rxjs";
import {catchError, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  JWT_TOKEN:string = "JWT_TOKEN";

  private bowiBffAPIUrl = environment.apiBaseUrl;  // URL to web api
  private clientPath = environment.clientPath;

  registrant:any;

  constructor(
    private http:HttpClient
  ) { }

  lookupClient(registrationKey: string): Observable<any>{
    const url = `${this.bowiBffAPIUrl}/${this.clientPath}/lookup/key/${registrationKey}`;
    return this.http.get<any>(url, {observe: 'response'}).pipe(
      tap((response:any) => {
        if(response) {
          const jwtToken = response.headers.get('X-JWT-Token');
          const userData = response.body;
          if (jwtToken && userData) {
            this.setToken(jwtToken);
            this.registrant = userData;
          }
        }
        return throwError(of([])); //Unauthorized. Did not return a token
      }),
      catchError(err => {
        //Unauthorizaed. Returned 401 or 403
        return throwError(err);
      })
    )
  }

  setToken(token:string):void {
    sessionStorage.setItem(this.JWT_TOKEN, token);
  }

  getToken():string|null {
    return sessionStorage.getItem(this.JWT_TOKEN);
  }
}
