import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from './../environments/environment';
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    origin = document.location.origin;

    private emailPath = environment.emailPath;

    constructor(private http: HttpClient) { }
 
    errorHandler(err: HttpErrorResponse) {
      let errorMessage: string|undefined;
      if (err.error instanceof Error) {
        errorMessage = "An error occurred [" + err.message + "]";
      } else {
        errorMessage = "Cannot connect to local server [" + err.name + "]";
      }
      return throwError(() => new Error(`Something bad happened; please try again later.[${errorMessage}]`));
    }


    contactMessage(data:any): Observable<any> {
      return this.http.post<any>(`${this.origin}/${this.emailPath}/contact`, data).pipe(
          catchError((err) => {
              return this.errorHandler(err);
          })
      );
    }
}
