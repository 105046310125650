<div class="container container-lg">

  <div class="row mt-3">
    <div class="col-lg-12">
     <h1>How to get a BOWI?</h1>
    </div>
  </div>

	<div class="row mt-3">
		<div class="col-lg-12">
      <h3>BOWI Partners</h3>
			<p>If your organization/company wants to use BOWIs for internal use, you can become a BOWI Partner.  Please contact us to learn more on our terms and conditions. </p>
      <p>If you are already a BOWI Partner, you can directly use your Registration Key here to register your works:</p>
		</div>
	</div>

	<form [formGroup]="form">
		<div class="row">
			<div class="col-md-5">
				<div class="form-group mb-3">
					<input type="text" class="form-control" formControlName="registrationKey" name="registrationKey" aria-label="registrationKey" aria-describedby="registrationKey" placeholder="Registration key"
					[ngClass]="{ 'is-invalid': isRegistrationKeyEmpty() == true  || invalidKey}" (keyup.enter)="onSubmit()" />
					<label id="validation-key-error" class="error small form-text invalid-feedback" *ngIf="invalidKey">The registration key is invalid</label>
					<label id="required-key-error" class="error small form-text invalid-feedback" *ngIf="isRegistrationKeyEmpty()">The registration key must be provided</label>
				</div>
			</div>
			<div class="col-md-5">
				<button type="button" *ngIf="!queryInProgress" class="btn btn-primary" (click)="onSubmit()" [attr.disabled]="form.controls['registrationKey'].errors?.['required'] ? true: null">Get a BOWI</button>
				<div *ngIf="queryInProgress" class="spinner-border ]text-primary me-2" role="status"></div>
			</div>
		</div>
	</form>

	<div class="row mt-3">
		<div class="col col-lg-12">
			<h3>BOWI Registration Agencies</h3>
			<p>If your organization/company wants to create and provide value added service on top of BOWI you can apply to become a BOWI Registration Agency.</p>
      <p>Please contact us to learn more on our terms and conditions. </p>
		</div>
	</div>
</div>
