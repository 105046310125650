import { Component, Input, OnInit } from '@angular/core';

import { WorkResult } from '../model';

@Component({
  selector: 'app-bowi-work-card',
  templateUrl: './bowi-work-card.component.html',
  styleUrls: ['./bowi-work-card.component.css']
})
export class BowiWorkCardComponent implements OnInit {
	@Input() work:WorkResult;

	constructor() {
		this.work = {
			  "bowi": "",
			  "workTitle": "",
        "subtitle": "",
			  "contributors": [
			    {
			      "name": {
			        "firstname": "",
			        "lastname": "",
			        "nametype": ""
			      },
			      "isni": "",
			      "ipi": "",
			      "role": ""
			    }
			  ],
			  "creationDate": "",
        "durationMs": 0,
			  "registrationDate": "",
			  "registrant": ""
		}

	}

	ngOnInit(): void {

	}

}
