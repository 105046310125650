import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { BowiLookupComponent } from './bowi-lookup/bowi-lookup.component';
import { BowiAssignComponent } from './bowi-assign/bowi-assign.component';
import { BowiSearchComponent } from './bowi-search/bowi-search.component';
import { BowiResultsComponent } from './bowi-results/bowi-results.component';
import { PageGetABowiComponent } from './page-get-a-bowi/page-get-a-bowi.component';
import { PageBowiAPIComponent } from './page-bowi-api/page-bowi-api.component';
import { PageRegistryComponent } from './page-registry/page-registry.component';
import { BowiBrowseComponent } from './bowi-browse/bowi-browse.component';
import { PageFaqComponent } from './page-faq/page-faq.component';
import { PageAboutComponent } from './page-about/page-about.component';
import { PageAssignComponent } from './page-assign/page-assign.component';

import { AppRoutingModule } from './app-routing.module';
import { PageFaqItemComponent } from './page-faq-item/page-faq-item.component';
import { BowiNavbarComponent } from './bowi-navbar/bowi-navbar.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { ToolHelpComponent } from './tool-help/tool-help.component';
import { BowiAssignSuccessComponent } from './bowi-assign-success/bowi-assign-success.component';
import { BowiAssignMatchComponent } from './bowi-assign-match/bowi-assign-match.component';
import { BowiWorkCardComponent } from './bowi-work-card/bowi-work-card.component';
import { BowiDownloadComponent } from './bowi-download/bowi-download.component';

import { DatepickerDirective } from './utils/datepicker.directive';
import { DurationPipe } from './utils/duration.pipe';
import { CreationDatePipe } from './utils/creation-date.pipe';
import { PartnersComponent } from './page-partners/partners.component';
import {IswcPipe} from './utils/iswc.pipe';
import {IsniPipe} from './utils/isni.pipe';
import { BowiRegistrationComponent } from './bowi-registration/bowi-registration.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { MatExpansionModule } from '@angular/material/expansion'
import { MatTabsModule } from '@angular/material/tabs'

@NgModule({
  declarations: [
    AppComponent,
    BowiLookupComponent,
    BowiAssignComponent,
    BowiSearchComponent,
    BowiResultsComponent,
    PageGetABowiComponent,
    PageBowiAPIComponent,
    PageRegistryComponent,
    BowiBrowseComponent,
    PageFaqComponent,
    PageAboutComponent,
    PageFaqItemComponent,
    PageAssignComponent,
    BowiNavbarComponent,
    PageHomeComponent,
    ToolHelpComponent,
    BowiAssignSuccessComponent,
    BowiAssignMatchComponent,
    BowiWorkCardComponent,
    BowiDownloadComponent,
    DatepickerDirective,
    DurationPipe,
    CreationDatePipe,
    IswcPipe,
    IsniPipe,
    PartnersComponent,
    BowiRegistrationComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatTabsModule
  ],
  providers: [
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
