import { Component, Input, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-tool-help',
  templateUrl: './tool-help.component.html',
  styleUrls: ['./tool-help.component.css']
})
export class ToolHelpComponent implements OnInit {
	@Input() modalId = '';
	@Input() title = '';
	@Input() text = '';

  constructor() {
  }

  ngOnInit(): void {
  }

  openModal(): void {
    console.log("openModal");
    $('#modal' + this.modalId).modal('show');
  }

}
