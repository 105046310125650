import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, map, tap } from 'rxjs/operators';

import { MessageService } from './message.service';
import {Registrant, AssignmentRequest, AssignmentResponse, APIResponse, Work, WorkResult, SearchResult} from './model';

import { environment } from './../environments/environment';
import {ClientService} from "./client.service";

@Injectable({providedIn: 'root'})
export class BowiService {
    prodMode: boolean = false;
    assignResults: AssignmentResponse[] = [];

    private bowiBffAPIUrl = environment.apiBaseUrl;  // URL to web api
    private bowiPath = environment.bowiPath;



    constructor(
        private messageService: MessageService,
  		  private clientService: ClientService,
  		  private http: HttpClient
  	) { }

    errorHandler(err: HttpErrorResponse): Observable<never>{
        console.log('errorHandler');
        let errorMessage;
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = "An error occurred [" + err.message + "]";
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = "Cannot connect to local server [" + err.name + "]";
        }

        this.messageService.pushActivity({
            queryInProgress: false,
            errorMessage: errorMessage
        });

        // ...optionally return a default fallback value so app can continue (pick one)
        // which could be a default value
        // return Observable.of<any>({my: "default value..."});
        // or simply an empty observable
        return throwError('Something bad happened; please try again later.');
    }

  	lookup(bowi: string): Observable<WorkResult[]> {
  		  const url = `${this.bowiBffAPIUrl}/${this.bowiPath}/lookup/${bowi}`;
  		  return this.http.get<APIResponse>(url).pipe(
            map((response: APIResponse) => response.results.works || []),
            catchError((err) => this.errorHandler(err))
        );
	  }

	  // searchByTitle(title: string): Observable<WorkResult[]> {
  	// 	  return this.search(`${this.bowiBffAPIUrl}/${this.bowiPath}/search?title=${title}`);
	  // }
    //
    // searchByContributor(contributor: string): Observable<WorkResult[]> {
    //   return this.search(`${this.bowiBffAPIUrl}/${this.bowiPath}/search?contributors=${contributor}`);
    // }

    searchMulti(params: any): Observable<SearchResult> {
      return this.search(`${this.bowiBffAPIUrl}/${this.bowiPath}/search?title=${params.title}&contributors=${params.contributor}`)
    }

    search(url: string): Observable<SearchResult> {
      return this.http.get<APIResponse>(url).pipe(
        map((response: APIResponse) => {
          return {
            works: response.results.works,
            totalWorks: response.totalResults
          };
        }),
        catchError((err) => {
          return this.errorHandler(err);
        })
      );
    }

    // buildQueryParams(params: any): string {
    //   let queryParams = '';
    //   if (params.contributor) { queryParams = `${queryParams}contributors=${params.contributor}&`; }
    //   if (params.contributor) { queryParams = `${queryParams}title=${params.title}`; }
    //   console.log(queryParams);
    //   return queryParams;
    // }


    assign(assignment: AssignmentRequest): Observable<APIResponse> {
        const token = this.clientService.getToken();
        // if(token == null) return of({status:"KO", results: { works: []}})

        const url = `${this.bowiBffAPIUrl}/${this.bowiPath}/assign?prodMode=${this.prodMode}`;
        return this.http.post<APIResponse>(url, assignment, { headers: new HttpHeaders().set('Authorization', "Bearer " + token)}).pipe(
            catchError((err) => {
               return this.errorHandler(err);
            })
        );
  	}


    downloadRegistry(data: any): Observable<any> {
        const url = `${this.bowiBffAPIUrl}/${this.bowiPath}/download`;
        return this.http.post<any>(url, data).pipe(
            catchError((err) => {
              console.log(err);
               return this.errorHandler(err);
            })
        );
    }


    setAssignResults(data: AssignmentResponse[]): void {
      this.assignResults = data;
    }

    getAssignResults(): AssignmentResponse[] {
      return this.assignResults;
    }
}
