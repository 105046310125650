
<form (ngSubmit)="onSubmit()">
<div class="row mt-3">
  <div class="col-md-2">
    <p class="h3">Lookup</p>
  </div>
    <div class="col">
      <div class="form-group">
        <input type="text" class="form-control" placeholder="by BOWI" id="bowi" required [(ngModel)]="bowi" name="bowi" [ngClass]="{ 'is-invalid': isBowiEmpty() || isBowiInvalidFormat() }"/>
        <p *ngIf="isBowiEmpty()" id="bowi_required_error" class="invalid-feedback">A BOWI is required for the lookup.</p>
        <p *ngIf="isBowiInvalidFormat()" id="bowi_format_error" class="invalid-feedback">Invalid format for the BOWI. Please check the "FAQ" section.</p>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <button type="submit" class="btn btn-primary">Lookup</button>
      </div>
    </div>
  </div>
</form>
<div class="row mt-3">
  <div class="col">
    <hr/>
  </div>
</div>

<div class="row mt-3">
  <div class="col-md-2">
    <p class="h3">Results</p>
  </div>
  <div class="col-md-10">
    <app-bowi-work-card [work]="result" *ngIf="!isResultUndefined()"></app-bowi-work-card>
  </div>
</div>
<!-- <app-bowi-results [results]="results"></app-bowi-results> -->
