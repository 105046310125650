import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import {BowiService } from '../bowi.service';

@Component({
  selector: 'app-bowi-navbar',
  templateUrl: './bowi-navbar.component.html',
  styleUrls: ['./bowi-navbar.component.css']
})
export class BowiNavbarComponent implements OnInit {
	 form: UntypedFormGroup;

  	constructor(
  		private bowiService:BowiService,
  		private formBuilder: UntypedFormBuilder) {
  		this.form = this.formBuilder.group({
            isProdMode: [false, '']
        });
  	}

  	ngOnInit(): void {
  	}

  	onToggleProdMode(): void {
  		this.bowiService.prodMode = this.form.value.isProdMode;
  	}

}
