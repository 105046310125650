<div class="card" >
	<div class="card-body">
    <div class="row">
      <div class="col-12 text-center">BOWI</div>
      <div class="col-12 text-center"><span class="badge rounded-pill bg-primary">{{work.formattedBowi || work.bowi}}</span></div>
    </div>
    <div class="row mt-3">
      <div class="col-6 text-end"><strong>Title</strong></div>
      <div class="col-6 text-start">{{work.workTitle}}</div>
    </div>
    <div class="row mt-3">
      <div class="col-6 text-end"><strong>Subtitle</strong></div>
      <div class="col-6 text-start">{{work.subtitle}}</div>
    </div>
    <div class="row">
      <div class="col-6 text-end"><strong>ISWC</strong></div>
      <div class="col-6 text-start">{{work.otherIds?.iswc || '-'}}</div>
    </div>
    <div class="row">
      <div class="col-6 text-end"><strong>Creation Date</strong></div>
      <div class="col-6 text-start">{{work.creationDate | creationDate}}</div>
    </div>
    <div class="row">
      <div class="col-6 text-end"><strong>Duration</strong></div>
      <div class="col-6 text-start">{{work.durationMs | duration}}</div>
    </div>
    <div class="row mt-2">
      <div class="col-6 text-end text-decoration-underline"><strong>Contributors</strong></div>
    </div>
    <div *ngFor="let contributor of work.contributors; index as i;">
      <div class="row">
        <div class="col-6 text-end"><strong>Name</strong></div>
        <div class="col-6 text-start">{{contributor.name.firstname}} {{contributor.name.lastname}}</div>
      </div>
      <div class="row">
        <div class="col-6 text-end"><strong>Role</strong></div>
        <div class="col-6 text-start">{{contributor.role}}</div>
      </div>
      <ng-container *ngIf="contributor.isni != null">
        <div class="row">
          <div class="col-6 text-end"><strong>ISNI</strong></div>
          <div class="col-6 text-start">{{contributor.isni}}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="contributor.ipi != null">
        <div class="row">
          <div class="col-6 text-end"><strong>IPI</strong></div>
          <div class="col-6 text-start">{{contributor.ipi}}</div>
        </div>
      </ng-container>
      <div class="row mb-2"></div>
    </div>
	</div>
</div>
