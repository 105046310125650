<div class="partners">
	<h1>BOWI Partners</h1>
	<div class="flex">
		<a href="https://vevacollect.com/" target="_blank">
			<img src='assets/images/veva.png' class="img-5">
		</a>
	  <a href="https://musive.bg/" target="_blank">
		<img src='assets/images/musive.png' class="img-2">
	  </a>
	</div>
	  
	  <h1 class="title">BOWI registration Agencies</h1>
	  <div class="flex">
		<a href="https://quansic.com/" target="_blank">
		<img src='assets/images/quansic.png' class="img-8">
	  </a>
	  <a href="https://www.bridgermusic.io/" target="_blank">
		<img src='assets/images/bridger.png' class="img-1">
	  </a>
	  <a href="https://www.rerightmusic.com/" target="_blank">
		<img src='assets/images/reright.png' class="img-3">
	  </a>
	  <a href="https://www.tribeofnoise.com/" target="_blank">
		<img src='assets/images/tribe.png' class="img-4">
	  </a>

	  <a href="https://opusmusic-africa.com/" target="_blank">
		<img src='assets/images/opus.png' class="img-6">
	  </a>
	  <a href="https://musicteam.com/" target="_blank">
		<img src='assets/images/musicteam.png' class="img-7">
	  </a>
	</div>
	</div>  