import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bowi-browse',
  templateUrl: './bowi-browse.component.html',
  styleUrls: ['./bowi-browse.component.css']
})
export class BowiBrowseComponent implements OnInit {
	pages:any = [];

  	constructor() { }

  	ngOnInit(): void {
  		this.pages = [
  			"0-9",
  			"A",
  			"B",
  			"C",
  			"D",
  			"E",
  			"F",
  			"G",
  			"H",
  			"I",
  			"J",
  			"K",
  			"L",
  			"M",
  			"N",
  			"O",
  			"P",
  			"Q",
  			"R",
  			"S",
  			"T",
  			"U",
  			"V",
  			"W",
  			"X",
  			"Y",
  			"Z"
  		]
  	}

}
