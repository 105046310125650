/* tslint:disable:no-trailing-whitespace */
import {Component, Input, OnInit, OnDestroy, AfterViewInit, ViewRef, ViewChild, ElementRef, ViewChildren, QueryList} from '@angular/core';
import {fromEvent, Observable, Subject, Subscription} from 'rxjs';
import { MessageService } from '../message.service';
import {Activity, SearchResult, WorkResult} from '../model';
import {debounceTime, distinctUntilChanged, map, tap, filter} from 'rxjs/operators';

@Component({
  selector: 'app-bowi-results',
  templateUrl: './bowi-results.component.html',
  styleUrls: ['./bowi-results.component.css']
})
export class BowiResultsComponent implements OnInit, AfterViewInit {
	  @Input() results: WorkResult[];
    @Input() isQueryInProgress: boolean;
    @Input() isQueryInError: boolean;
    @Input() errorMessage: string;

    @ViewChildren('toggleLink', {read: ElementRef}) toggleLinks!: QueryList<ElementRef>;
    @ViewChildren('subrow', {read: ElementRef}) subrows!: QueryList<ElementRef>;

    hasReceivedActivity: boolean;
	  worksSubscription: Subscription;
    activitySubscription: Subscription;

    filter$: Subject<any> = new Subject();

    rows: WorkResult[] = [];
    totalWorks: number = 0;

    @ViewChild('inputTitle', {static: false}) inputTitle!: ElementRef;

    filter: any = {
      bowi: '',
      title: '',
      contributor: '',
      iswc: ''
    };

  	constructor(private messageService: MessageService) {
      this.hasReceivedActivity = false;
  		this.results = [];
      this.isQueryInProgress = false;
      this.isQueryInError = false;
      this.errorMessage = "";

  		this.worksSubscription = this.messageService.getWorks()
        .subscribe((result: SearchResult) => {
          if (result) {
            this.results = result.works;
            this.rows = result.works;
            this.totalWorks = result.totalWorks || 0;
          }
        });

      this.activitySubscription = this.messageService.getActivity().subscribe(activity => {
          if(activity){
              this.hasReceivedActivity = true;
              this.isQueryInProgress = activity.queryInProgress;
              if(this.isQueryInProgress){
                  this.results = []; //reset the results
              }
              if(typeof(activity.errorMessage) !== 'undefined' &&  activity.errorMessage !== null && activity.errorMessage !== ''){
                  this.isQueryInError = true;
                  this.errorMessage = activity.errorMessage;
              } else {
                  this.isQueryInError = false;
                  this.errorMessage = "";
              }
          }
      });
  	}

    ngAfterViewInit(): void {
    }


    isResultsEmpty(): boolean{
        return this.hasReceivedActivity && !this.isQueryInProgress && !this.isQueryInError && this.results.length === 0;
    }

  	ngOnInit(): void {

  	}

  	ngOnDestroy(): void {
  		this.worksSubscription.unsubscribe();
      this.activitySubscription.unsubscribe();
  	}

    toggleDetailsRow(idx: number) {
      const subrow: ElementRef|undefined = this.subrows.get(idx);
      const toggleLink: ElementRef|undefined = this.toggleLinks.get(idx);
      if(subrow && toggleLink) {
        subrow.nativeElement.classList.toggle('hide');
        toggleLink.nativeElement.textContent = subrow.nativeElement.classList.contains('hide') ? 'see more' : 'see less';
      }
    }
}
