<div class="container-fluid home">
	<div class="row mt-3 mb-5">
		<div class="col-md-12 text-center">
      <img src="assets/images/FULL_SOFT.png" height="200px" alt="Bowi logo"/>
    </div>
	</div>

  <div class="row mt-3">
    <div class="col-md-12 text-center">
      <p>Welcome to the BOWI International Agency.</p>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12 text-center">
      <p>BOWI is an OPEN identifier for Musical Works.</p>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-10 offset-md-1 text-center">
      <p>We believe that ALL Music Assets should always be referred to with an open identifier.</p>
      <p>Along with ISNI (Parties) and ISRC (Sound Recordings), BOWI (Musical Works) completes the family of music identifiers open to all.</p>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
