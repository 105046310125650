<form [formGroup]="assignmentRequestForm" (keydown.enter)="$event.preventDefault()">

	<div class="row mt-3">
		<div class="col-md-12">
			<h4>Registrant information</h4>
			<dl class="row">
				<dt class="col-md-2">Name:</dt> <dd class="col-md-5">{{registrant.name}}</dd><div class="col-md-5"></div>
				<dt class="col-md-2">Email:</dt> <dd class="col-md-5">{{registrant.email}}</dd><div class="col-md-5"></div>
				<dt class="col-md-2">Registration key:</dt> <dd class="col-md-5">{{registrant.registrationKey}}</dd><div class="col-md-5"></div>
			</dl>

		</div>
	</div>
	<div class="row">
		  <div clas="col-md-12">
	    	  <div class="form-check form-switch">
            <input class="form-check-input " type="checkbox" id="testModeSwitch" formControlName="isProdMode" id="isProdMode" (change)="onToggleProdMode()" name="isProdMode" />
            <label class="form-check-label" for="testModeSwitch">
              <span *ngIf="f['isProdMode'].value">Prod</span>
              <span *ngIf="!f['isProdMode'].value">Test&nbsp;</span> mode
            </label>
            <app-tool-help modalId="testModeHelp" title="Help" text="<p>The dev mode is to be used to test the API. You are writing to the dev database, which gets regularly deleted.
A dev BOWI is returned, starting with “B0”. You may NOT use any “B0” BOWI in production. They are not valid BOWIs.</p><p>Once your tests are successful, you may switch to “prod” mode. You are then writing to the production database.
A prod BOWI is returned, starting with “B1”. Once registered, a “B1” BOWI may NOT be edited.</p>"></app-tool-help>
          </div>
		</div>
      <div class="modal fade show" id="prodModeModal" tabindex="-1" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Production mode</h5>
            </div>
            <div class="modal-body m-3">
              <p class="mb-0">Warning: You are switching to "Production" mode. Your data will be written to the public database.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="turnOffProdMode()">No</button>
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Yes</button>
            </div>
          </div>
        </div>
      </div>
	</div>

	<div class="row mt-4">
		<div class="col-md-12">
			<span class="text-danger">&nbsp;*</span> Fields marked with an asterisk are mandatory
		</div>
	</div>
	<div class="row mt-1">
		<div class="tab" [ngClass]="{'tab-vertical': verticalTab}">
			<ul class="nav nav-tabs" role="tablist">
				<li class="nav-item">
					<a class="nav-link" href="#tab-work" data-bs-toggle="tab" role="tab" aria-selected="true" [ngClass]="{'active': activeTab=='work'}">Musical Work</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="#tab-contributors" data-bs-toggle="tab" role="tab" aria-selected="true" [ngClass]="{'active': activeTab=='contributors'}">Contributors</a>
				</li>
			</ul>
			<div class="tab-content">
				<div class="tab-pane" id="tab-work" role="tabpanel" formGroupName="work" [ngClass]="{'active': activeTab=='work'}">
					<!-- <h4 class="tab-title">Musical Work</h4> -->
					<div class="row form-group mb-3">
						<div class="col-md-12">
							<label class="col-form-label">Title<span class="text-danger">&nbsp;*</span></label>
							<input type="text" class="form-control" aria-label="workTitle" aria-describedby="workTitle"  maxlength="1024"
								formControlName="title"
								[ngClass]="{ 'is-invalid': isWorkTitleInvalid() == true}">
							<label id="validationtitle-error" class="error small form-text invalid-feedback" *ngIf="isWorkTitleInvalid()">This field is required.</label>
						</div>
					</div>
          <div class="row form-group mb-3">
            <div class="col-md-12">
              <label class="col-form-label">Subtitle</label>
              <input type="text" class="form-control" aria-label="workSubtitle" aria-describedby="workSubtitle"  maxlength="1024" formControlName="subtitle">
            </div>
          </div>
					<div class="row form-group mb-3">
						<div class="col-md-6">
							<label class="col-form-label">Duration</label>
							<input type="text" class="form-control" aria-label="workDuration" aria-describedby="workDuration" placeholder="hh:mm:ss"
							formControlName="duration"
							[ngClass]="{ 'is-invalid': isWorkDurationInvalid() == true}">
							<label id="validation-duration-error" class="error small form-text invalid-feedback" *ngIf="isWorkDurationInvalid()">The duration must follow the format 00:00:00</label>
						</div>
						<div class="col-md-6">
							<label class="col-form-label">Year of Creation</label>
							<input type="text" class="form-control" formControlName="yearOfCreation" aria-label="workYearOfCreation" aria-describedby="workYearOfCreation" placeholder="YYYY or YYYY-mm or YYYY-mm-dd"
							[ngClass]="{ 'is-invalid': isWorkYearOfCreationInvalid() == true}">
							<label id="validation-creationDate-error" class="error small form-text invalid-feedback" *ngIf="isWorkYearOfCreationInvalid()">The year of creation must follow the format YYYY, or YYYY-mm, or YYYY-mm-dd</label>
						</div>
					</div>

					<div class="row mt-3">
						<div class="col">
              <p><strong>Additional Ids</strong></p>
						</div>
					</div>
					<div class="row" formGroupName="otherID">
						<div class="col-md-6">
							<label class="col-form-label">Type</label>
							<select class="form-select" formControlName="type" aria-label="AdditionalIdType">
								<option selected value="ISWC">ISWC</option>
								<!-- <option value="MLC_ID">MLC ID</option> -->
							</select>
						</div>
						<div class="col-md-6">
							<label class="col-form-label">Value</label>
							<input type="text" class="form-control" formControlName="value" aria-label="AdditionalIdValue" aria-describedby="AdditionalIdValue" [ngClass]="{ 'is-invalid': isISWCInvalid() == true}">
              <label id="validation-iswc-error" class="error small form-text invalid-feedback" *ngIf="isISWCInvalid()">The ISWC number must be a 'T' followed by 10 digits.</label>
						</div>
					</div>
          <div class="row mt-4">
            <div class="col-md-12">
              <a class="btn btn-sm btn-outline-primary" id="next-step" >Next Step</a>
            </div>
          </div>
				</div>
				<div class="tab-pane" id="tab-contributors" role="tabpanel"  [ngClass]="{'active': activeTab=='contributors'}">
					<!-- <h4 class="tab-title">Contributors</h4> -->
					<ng-container formArrayName="contributors">
				    	<div *ngFor="let _ of contributors.controls; index as idx" class="assign-box">
				    		<ng-container [formGroupName]="idx">
				    			<div class="row g-3">
							    	<div class="col-md-5">
							    		<label class="col-form-label">First Name</label>
							    		<input formControlName="firstName" class="form-control"/>
							    	</div>
							    	<div class="col-md-5">
							    		<label class="col-form-label">Last Name<span class="text-danger">&nbsp;*</span></label>
                      <app-tool-help modalId="nameHelp" title="Help" text="Name type is the usual artist name. It can be the real name or a pseudonym"></app-tool-help>
							    		<input class="form-control"  formControlName="lastName"
							    		[ngClass]="{ 'is-invalid': isContributorsLastNameInvalid(idx) == true}">
										<label id="validation-email-error" class="error small form-text invalid-feedback" *ngIf="isContributorsLastNameInvalid(idx)">This field is required.</label>
							    	</div>
							    	<div class="col-md-2 text-end">
							    		<button class="btn btn-sm btn-primary" (click)="removeCreator(idx)">
							    			<i class="fas fa-times"></i>
							    		</button>
							    	</div>
								</div>
									<div class="row g-3">
								    	<div class="col-md-5">
									    	<label class="col-form-label">Name type</label>
									    	<select class="form-select" formControlName="nameType">
											   <option *ngFor="let type of nameTypeList" [ngValue]="type.key">{{type.value}}</option>
											</select>
										</div>
								    	<div class="col-md-5">
									    	<label class="col-form-label">Role</label>
									    	<select class="form-select" formControlName="role">
											   <option *ngFor="let role of contributorRoleList" [ngValue]="role.key">{{role.value}}</option>
											</select>
										</div>
										<div class="col-md-2">
										</div>
								</div>
								<div class="row g-3 mt-3">
									<div class="col-md-4">
                    <p><strong>Additional Ids</strong></p>
									</div>
								</div>
								<div class="row">
							    	<div class="col-md-5">
								    	<label class="col-form-label">ISNI</label>
								    	<app-tool-help modalId="isniHelp" title="Help" text="ISNI is the ISO certified identifier for creative works contributors"></app-tool-help>
							    		<input formControlName="isni" class="form-control"
							    		[ngClass]="{ 'is-invalid': isContributorsIsniInvalid(idx) == true}">
										  <label id="validation-isni-invalid-error" class="error small form-text invalid-feedback" *ngIf="isContributorsIsniInvalid(idx)">This field must follow the ISNI format (16 digits or 15 digits and the character X).</label>
                      <label id="validation-isni-ipi-required-error" class="error small form-text text-danger" style="display:block;" *ngIf="isContributorsAdditionalIdRequired(idx)">At least one Isni or Ipi identifier is required.<br/></label>
							    		Don't have an ISNI ? <a href="https://quansic.com/isni-registration/" target="_blank">Click here </a>

							    	</div>
							    	<div class="col-md-5">
								    	<label class="col-form-label">IPI</label>
								    	<app-tool-help modalId="ipiHelp" title="Help" text="If the contributor is a member of a Collective Management Organisation member of CISAC, the CMO has assigned him/her an IPI."></app-tool-help>
							    		<input formControlName="ipi" class="form-control" [ngClass]="{ 'is-invalid': isContributorsIpiInvalid(idx) == true}">
                      <label id="validation-ipi-invalid-error" class="error small form-text invalid-feedback" *ngIf="isContributorsIpiInvalid(idx)">This field must follow the IPI format (11 digits).</label>
							    	</div>
                  <div class="col-md-2"></div>
							    </div>
						    </ng-container>
					    </div>
				  	</ng-container>

				  	<div class="row mt-3">
				  		<div class="col-md-6">
				  			<button class="btn btn-outline-primary" (click)="addCreator()"> + add a contributor</button>
				  		</div>
				  	</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row mt-3 mb-5">
		<div class="col-md-12">
			<button *ngIf="!queryInProgress" type="button" class="btn btn-lg btn-primary" (click)="onSubmit()" [attr.disabled]="isFormInvalid()? true: null">Create a BOWI</button>
			<div *ngIf="queryInProgress" class="spinner-border text-primary me-2" role="status"></div>
      <div *ngIf="errorMessage != ''" class="text-danger">{{errorMessage}}</div>
		</div>
	</div>
  <div class="row mt-5">&nbsp;</div>
