import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-faq-item',
  templateUrl: './page-faq-item.component.html',
  styleUrls: ['./page-faq-item.component.css']
})
export class PageFaqItemComponent implements OnInit {
	@Input() question: string;
	@Input() answer: string;
	@Input() id: number;

  	constructor() { 
  		this.question = "";
  		this.answer = "";
  		this.id = 0;
  	}

  	ngOnInit(): void {
  	}

}
